<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Sponsor List</h4>
          <div class="d-flex justify-content-start">

            <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['sponsors/create'])"
                         :to="'/sponsors/create'"
                         class="btn btn-info btn-wd">
              Add New
              <span class="btn-label">
                      <i class="fa fa-plus"></i>
                  </span>
            </router-link>
          </div>

        </div>
        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Forum"
              :input-classes="'select-default'"
              :list="filterBuilders.forums"
              :listItemLabel="'title'"
              :listItemValue="'id'"
              v-model="filters.forum_id">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->
        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'sponsors/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['sponsors/change-active-status'])"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['sponsors/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/sponsors/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i></router-link>

                <a v-if="$store.getters['auth/haveOneOfPermissions'](['sponsors/delete'])"
                   v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>

          </general-data-table>
        </div>

      </card>


      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this sponsor?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {DeleteModal as DeleteModal, NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Website', value: 'website', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },

      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      filterBuilders: {
        forums: []
      },
      filters: {
        forum_id: null,
        type: 'sponsor'
      },
    }
  },
  async mounted() {
    let response = await this.axios.post("sponsors/builder");
    this.filterBuilders.forums = response.data.forums;
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
      }
      this.axios.post("sponsors/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Sponsor updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    confirmDeleteModal() {

      let data = {
        'id': this.toDeleteId,
      }
      this.axios.delete("sponsors/delete", {headers: {}, data}).then((response) => {
        this.$notify({
          message: "Sponsor deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.forum_id) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        let forum_id = this.filters.forum_id;
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          data: orderData,
          forum_id: forum_id
        }

        await this.axios.post("sponsors/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },
  },

}
</script>
